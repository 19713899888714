<template>
    <DxValidationGroup ref="formValidation">
        <div class="title pt-2 pb-4 font-28">
            Vui lòng hướng camera vào mã QR
        </div>
        <div class="row justify-center">
            <div>
                <div
                    class="pa-2 row justify-center align-center"
                    style="
                        border: 1px solid #ddd;
                        border-radius: 7px;
                        width: 280px;
                        height: 280px;
                        position: relative;
                    "
                >
                    <div v-if="hetThoiGianThanhToan">
                        <div
                            style="
                                position: absolute;
                                top: 35%;
                                left: 18%;
                                font-size: 18px;
                                z-index: 3;
                            "
                            class="column align-center"
                        >
                            Hết thời gian thanh toán
                            <DxButton
                                id="btn-tailai"
                                text="Tải lại"
                                type="default"
                                style="font-size: 38px"
                                styling-mode="contained"
                                icon="mdi mdi-refresh"
                                class="mt-3"
                                @click="thucHienThanhToan"
                            />
                        </div>
                    </div>
                    <div :style="hetThoiGianThanhToan ? 'opacity:0.1' : ''">
                        <QrCodeVue
                            :data="thongTinQrCode.qrCode"
                            style="background-color: white"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center" :class="`pt-3 pb-3 ${color}--text`">
            <span class="font-weight-regular font-24"
                >Thời gian thanh toán:</span
            ><span class="font-weight-regular pl-4 font-24">{{
                time_session
            }}</span>
        </div>
        <div style="text-align: center">
            <span
                class="
                    font-weight-regular font-weight-light font-italic font-20
                    note
                "
                >(Sử dụng ViettelPay quét mã QR Code để tiến hành thanh
                toán)</span
            >
        </div>
        <div
            class="row justify-space-around py-3"
            style="width: 90%; margin: 0px auto !important"
        >
            <div class="my-2 font-28 font-bold">Số tiền thanh toán:</div>
            <div class="my-2 font-28 font-bold">
                {{ $MoneyFormat(thongTinQrCode.tongTienThanhToan) }} đồng
            </div>
        </div>
    </DxValidationGroup>
    <div class="boundary container mb-3 mt-4">
        <div class="font-22 font-bold text-xs-center pb-4">
            Thông tin người nhận
        </div>
        <div class="row pb-3">
            <div class="xs5 font-22">Người nhận:</div>
            <div class="xs7 font-22">
                {{ getThongTinTaiKhoanThanhToan.nguoiNhan }}
            </div>
        </div>
        <div class="row pb-3">
            <div class="xs5 font-22">Số tài khoản nhận:</div>
            <div class="xs7 font-22">
                {{ getThongTinTaiKhoanThanhToan.taiKhoan }}
            </div>
        </div>
        <div class="row pb-3">
            <div class="xs5 font-22">Số điện thoại:</div>
            <div class="xs7 font-22">
                {{ getThongTinTaiKhoanThanhToan.soDienThoai }}
            </div>
        </div>
        <div class="row pb-3">
            <div class="xs5 font-22">Ngân hàng:</div>
            <div class="xs7 font-22">
                {{ getThongTinTaiKhoanThanhToan.tenNganHang }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { DxButton, DxValidationGroup } from "devextreme-vue";
import QrCodeVue from "./QrCode";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    components: { QrCodeVue, DxButton, DxValidationGroup },
    props: {
        isShowDialog: { type: Boolean, default: false },
    },
    data() {
        return {
            color: "primary",
            time_session: "",
            time_remaining: 0,
            timerQrCode: null,
            hetThoiGianThanhToan: false,
            thongTinQrCode: {
                tongTienThanhToan: 0,
                qrCode: null,
                thoiGianHetHanThanhToan: 300,
            },
        };
    },
    computed: {
        ...mapGetters("thongTinThanhToan", [
            "getSoChoMuaGhePhu",
            "getThongTinTaiKhoanThanhToan",
        ]),
        ...mapGetters("soDoCho", ["getDanhSachChoDaChonLocal"]),
        ...mapGetters("trangChu", ["getCauHinhChung"]),
    },
    methods: {
        ...mapMutations("soDoCho", ["removeDanhSachChoDaChonLocal"]),
        ...mapActions("idleTime", ["stopIdleTimer", "startIdleTimer"]),
        ...mapActions("thongTinThanhToan", [
            "thanhToan",
            "thanhToanChoGhePhu",
            "kiemTraThanhToan",
            "ketQuaGiaoDich",
            "layThongTinTaiKhoanThanhToan",
        ]),
        async thucHienThanhToan() {
            try {
                this.hetThoiGianThanhToan = false;
                let uid = "00000000-0000-0000-0000-000000000000";
                let dsIdChoDaChon = [];
                if (
                    this.getDanhSachChoDaChonLocal &&
                    this.getDanhSachChoDaChonLocal.length > 0
                ) {
                    dsIdChoDaChon = this.getDanhSachChoDaChonLocal.map(
                        (e) => e.id
                    );
                }
                //Khởi tạo thanh toán
                let xeHetCho =
                    this.$route.query.xeHetCho == "true"
                        ? true
                        : this.$route.query.xeHetCho == "false"
                        ? false
                        : null;
                let xeKhongCoSoDoCho =
                    this.$route.query.xeKhongCoSoDoCho == "true"
                        ? true
                        : this.$route.query.xeKhongCoSoDoCho == "false"
                        ? false
                        : null;
                        
                if(xeHetCho || xeKhongCoSoDoCho){
                    for (let i = 1; i <= this.getSoChoMuaGhePhu; i++) {
                        dsIdChoDaChon.push(uid)
                    }
                }
                //Bán vé ghế chính, phụ, không có sơ đồ
                let rsThanhToan = await this.thanhToan({
                    url: xeHetCho
                        ? "ThanhToanChoGhePhu"
                        : xeKhongCoSoDoCho
                        ? "ThanhToanChoXeKhongSoDo"
                        : "ThanhToan",
                    maChuyenDi: this.$route.query.chuyenDi_Id,
                    guidDoanhNghiep: this.$route.query.doanhNghiep_Id,
                    danhSachMaSoGhe: dsIdChoDaChon,
                });
                if (rsThanhToan.StatusCode != 200) {
                    this.$emit(
                        "EmitError",
                        rsThanhToan.Data.errorCode,
                        "Đã xảy ra lỗi. Vui lòng thử lại!"
                    );
                    return;
                }

                //Thông báo lỗi
                //1106 chỗ không hợp lệ
                //1107 Chuyến đi không hợp lệ
                switch (rsThanhToan.Data.errorCode) {
                    case 1106:
                        this.$emit(
                            "EmitError",
                            rsThanhToan.Data.errorCode,
                            rsThanhToan.Data.message
                        );
                        this.removeDanhSachChoDaChonLocal();
                        break;
                    case 1107:
                        this.$emit(
                            "EmitError",
                            rsThanhToan.Data.errorCode,
                            rsThanhToan.Data.message
                        );
                        this.removeDanhSachChoDaChonLocal();
                        setTimeout(() => {
                            this.$router.push("/tim-kiem-chuyen-di");
                        }, 3000);
                        break;
                    default:
                        break;
                }

                //Hiển thị popup quét mã QrCode
                let thoiGianHetHanTT =
                    new Date(rsThanhToan.Data.data.thoiGianHetHanThanhToan) -
                    new Date(rsThanhToan.Data.data.thoiGianHienTai);
                this.thongTinQrCode = {
                    tongTienThanhToan: rsThanhToan.Data.data.tongTienThanhToan,
                    qrCode: rsThanhToan.Data.data.qrCode,
                    thoiGianHetHanThanhToan: thoiGianHetHanTT / 1000,
                };
                this.timeSesstion(this.thongTinQrCode.thoiGianHetHanThanhToan);
                //Kiểm tra thanh toán
                let self = this;
                async function kiemTraTT() {
                    if (!self.isShowDialog) {
                        return false;
                    }
                    let rsKiemTraThanhToan = await self.kiemTraThanhToan(
                        rsThanhToan.Data.data.maGiaoDich
                    );
                    if (rsKiemTraThanhToan.StatusCode == 200) {
                        switch (rsKiemTraThanhToan.Data.errorCode) {
                            case 1105:
                                self.$emit(
                                    "EmitError",
                                    rsKiemTraThanhToan.Data.errorCode
                                ); //Đóng popup qr
                                break;
                            case 1103:
                                self.hetThoiGianThanhToan = true; //Hết thời gian thanh toán
                                break;
                            case 1111:
                                const sleep = (m) =>
                                    new Promise((r) => setTimeout(r, m));
                                await sleep(1000);
                                // giải pháp lỗi timeout
                                return await kiemTraTT();
                            default:
                                break;
                        }
                    }
                    return rsKiemTraThanhToan.Data.data;
                }
                let kt = false;
                kt = await kiemTraTT();
                if (!kt) {
                    return;
                }
                //Lấy kết kết quả giao dịch
                let rsKetQuaGiaoDich = await this.ketQuaGiaoDich(
                    rsThanhToan.Data.data.maGiaoDich
                );
                if (rsKetQuaGiaoDich.Data.errorCode != 0) {
                    this.$emit(
                        "EmitError",
                        rsKetQuaGiaoDich.Data.errorCode,
                        "Đã xảy ra lỗi. Vui lòng thử lại!"
                    );
                    return;
                }
                // localStorage.setItem(
                //     "KetQuaGiaoDich",
                //     JSON.stringify(rsKetQuaGiaoDich)
                // );
                //Gửi dữ liệu sang trang in vé
                this.$router.push({
                    path: "/in-ve",
                    query: { maGiaoDich: rsThanhToan.Data.data.maGiaoDich },
                });
            } catch (error) {
                throw new Error(error);
            }
        },
        timeSesstion(duration) {
            this.time_remaining = 0;
            let timer = duration,
                minutes,
                seconds;
            let self = this;
            if (this.timerQrCode) {
                clearInterval(this.timerQrCode);
            }
            this.timerQrCode = setInterval(function () {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                self.time_session = minutes + ":" + seconds;
                if (minutes >= 1 && minutes <= 2) {
                    self.color = "warning";
                } else if (minutes < 1) {
                    self.color = "danger";
                } else {
                    self.color = "primary";
                }
                timer--;
                self.time_remaining = timer;
                if (self.time_remaining < 0) {
                    clearInterval(self.timerQrCode);
                    return;
                }
            }, 1000);
        },
    },
    watch: {
        isShowDialog: {
            handler: function () {
                if (this.isShowDialog == false) {
                    clearInterval(this.timerQrCode);
                    this.startIdleTimer(
                        this.getCauHinhChung.thoiGianQuayLaiTrangChu
                    ); //Quay lại trang chủ khi không thanh toán
                } else {
                    this.stopIdleTimer(); //Không quay lại trang chủ khi đang thanh toán
                    this.thucHienThanhToan();
                    this.layThongTinTaiKhoanThanhToan();
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style scoped>
.close-style {
    position: absolute;
    top: 0;
    right: 0;
}
.font-28 {
    font-size: 28px;
}
</style>
