<template>
    <div class="qrcodes">
        <div :id="`qrcode${id || 1}`"></div>
    </div>
</template>

<script>
export default {
    props: {
        id:{type:[Number,String],default:1},
        data: { type: String, default: "" },
    },
    data() {
        return {
            qrcode: null,
        };
    },
    mounted() {
        this.qrcode = new QRCode(
            document.getElementById("qrcode" + (this.id || 1)),
            {
                text: this.data || null,
                correctLevel: QRCode.CorrectLevel.L,
            }
        );
        this.qrcode._el.title = "";
    },
    methods: {
        getQrCode() {
            if (this.qrcode) {
                this.qrcode.clear();
                this.qrcode.makeCode(this.data || null);
                this.qrcode._el.title = "";
            }
        },
    },
    beforeDestroy() {
        if (window.timer_qr) {
            clearInterval(window.timer_qr);
        }
    },
    watch: {
        data: {
            handler: function () {
                setTimeout(() => {
                    this.getQrCode();
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style scoped>
.close-style {
    position: absolute;
    top: 0;
    right: 0;
}

</style>
<style lang="scss" scoped>
.qrcodes{
    border:1px solid $border;
    border-radius: $border_radius;
}
</style>